import React, {useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    BooleanField,
    ChipField,
    Create,
    Datagrid,
/*
    DatagridConfigurable,
*/
    DateField,
    Edit,
    EditButton,
    EmailField,
    FormTab,
    FunctionField,
    DateInput,
    List,
    Pagination,
    ReferenceField,
    SelectInput,
    SimpleForm,
    TabbedForm,
    TextField,
    TextInput,
    SearchInput,
    downloadCSV,
    useRecordContext,
    useGetList,
    useNotify,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import {
    Button,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions, Box,
    IconButton
} from "@mui/material";
import {ProjectStatusIcon} from './ProjectStatusIcon';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import {
    EmailOutlined,
    FileCopyOutlined,
    Visibility,
    //VisibilityOff,
} from '@mui/icons-material'
import moment from "moment";

const status = [
    {id: 0, name: 'abgelehnt'},
    {id: 1, name: 'wartend'},
    {id: 2, name: 'freigegeben'}
];

// Transform API response for logo to match react-admin expectations (https://github.com/marmelab/react-admin/issues/2077#issuecomment-516821629)
/*
function formatImageUrlString(value) {
    if (!value || typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
        return {url: value};
    } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
        return value;
    }
}
*/

const ProjectGallery = () => {
    const record = useRecordContext();
    return (
        <>
            {record.projectGallery && record.projectGallery.map((imageURL, index) => {
                return (
                    <Grid item xs={12} md={3} xl={6}>
                        <Card style={{display: 'flex'}}>
                            <CardMedia
                                style={{width: 151}}
                                image={imageURL}
                                title={`Projektgalleriebild Nr. ${index}`}
                                alt={`Projektgalleriebild Nr. ${index}`}
                            />
                            <Box style={{display: 'flex', flexDirection: 'column'}}>
                                <CardContent style={{flex: '1 0 auto'}}>
                                    <Typography color="textSecondary">
                                        {`Bild Nr. ${index}`}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        {imageURL}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" color="primary" disabled>
                                        Entfernen
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )
            })}
        </>
    )
};

const ProjectsPagination = props => <Pagination rowsPerPageOptions={[500]} {...props} />;

export const ProjectList = props => {

    const exporter = projects => {
        let inputString = process.env.REACT_APP_SERVER_URL;
        let secondSlashIndex = inputString.indexOf("/", inputString.indexOf("/") + 1);
        let firstDotIndex = inputString.indexOf(".", secondSlashIndex);
        let result = inputString.substring(secondSlashIndex + 1, firstDotIndex);
        let currentDate = moment().format("YYMMDDTHHmmss");

        const csv = convertToCSV({
            data: projects,
            fields: [
                '_id',
                'status',
                'contestantName',
                'projectTitle',
                'creationDate',
                'projectCategory',
                'contestantStreet',
                'contestantZipcode',
                'contestantCity',
                'author',
                'referrer',
                'voteCount',
                'voteCountFinale'
            ]
        });
        downloadCSV(csv, `${result}-export-users-${currentDate}`); // download as 'posts.csv` file
    }

    // State setup
    //const [value, setValue] = useState('');
    const [visible, setVisibility] = useState(false);

    // List filters setup
    const projectsFilters = [<SearchInput source="q" alwaysOn/>];

    // State handling for copy-to-clipboard
    const notify = useNotify();
    const handleCopy = () => {
        //setCopied(true);
        notify('In Zwischenablage kopiert', {type: "success"});
    };

    const handleUncover = () => {
        setVisibility(true);
    }

    const UncoveredEmailField = () => {
        const record = useRecordContext();
        if (!record) return null;
        return <EmailField source={record.email}/>
    }

    const EmailActionButtons = () => {
        const record = useRecordContext();
        if (!record) return null;
        return (
            <div style={{minWidth: '150px'}}>
                <IconButton onClick={handleUncover}>
                    <Visibility fontSize="small"/>
                </IconButton>
                <IconButton component="a" href={`mailto:${record.email}`}><EmailOutlined fontSize="small"/></IconButton>
                <CopyToClipboard text={record.email} onCopy={handleCopy}>
                    <IconButton><FileCopyOutlined fontSize="small"/></IconButton>
                </CopyToClipboard>
            </div>
        )
    }

    const CustomContestantNameField = () => {
        const record = useRecordContext();
        return (
            <a
                href={`${process.env.REACT_APP_SITE_URL}/projekte/${record._id}`}
                target="_blank"
                rel="noopener noreferrer">
                {record.contestantName}
            </a>
        )
    };

    const CommentField = () => {
        const record = useRecordContext();
        if (record.commentCount > 0) {
            return <ChipField label="Kommentare" source="commentCount" color="primary" size="small"/>
        }
        return <ChipField label="Kommentare" source="commentCount" size="small"/>
    };

    const ConditionalField = () => {
        const record = useRecordContext();
        if (record.projectCategory) {
            return <TextField label="Kategorie" source="projectCategory" />
        }
    }

    return (
        <List
            {...props}
            exporter={exporter}
            sort={{field: 'creationDate', order: 'DESC'}}
            pagination={<ProjectsPagination/>}
            filters={projectsFilters}
            perPage={200}>
            <Datagrid optimized size="small">
                <ProjectStatusIcon label="Status" source="status"/>
                <CommentField label="Kommentare"/>
                <ConditionalField source="projectCategory" label="Kategorie"/>
                <BooleanField label="Finalist?" source="isFinalist"/>
                <CustomContestantNameField/>
                <TextField label="Projekttitel" source="projectTitle"/>
                <TextField label="Fingerprint-Stimmen" source="voteCount"/>
                <TextField label="SMS-Stimmen" source="voteCountFinale"/>
                <DateField label="Bewerbungsdatum" source="creationDate"/>
                <ReferenceField
                    label="Ansprechpartner"
                    source="author"
                    reference="users">
                    <FunctionField render={author => `${author.salutation} ${author.firstname} ${author.lastname}`}/>
                </ReferenceField>
                <ReferenceField
                    label="E-Mail-Adresse"
                    link={false}
                    source="author"
                    reference="users">
                    {visible ? <UncoveredEmailField/> : <EmailActionButtons/>}
                </ReferenceField>
                <EditButton/>
            </Datagrid>

        </List>
    )
};

export const ProjectEdit = props => {
    const {data, isLoading, error} = useGetList('options');
    if (error) { return <p>Es ist ein Fehler aufgetreten:</p> }
    if (isLoading) { return 'Lade Kategorien...'; }

    let choicesCategories = []
    let categoriesExist = false
    if (data[0].projectForm.projectCategory) {
        categoriesExist = true
        choicesCategories = data[0].projectForm.projectCategory.elementConfig.options.map(category => ({
            id: category.value,
            name: category.displayValue,
        }));
    }

    return (
        <Edit {...props}>
            <TabbedForm>
                <FormTab label="Teilnehmerdaten">
                    <Grid container spacing={2} sx={{p: 2}}>
                        <Grid container xs={12} lg={3} spacing={2}>
                            <Grid item xs={12}>
                                <img style={{width: '90%', height: 'auto'}}
                                     src="https://placehold.co/390x290?text=Projektlogo" alt="Projektlogo"/>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} lg={9} spacing={2}>
                            <Grid item xs={12}>
                                <h5>Projektstatus und -adresse</h5>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <DateInput disabled fullWidth label="Bewerbungsdatum" source="creationDate"/>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectInput fullWidth source="status" style={{marginTop: 0}} choices={status}/>
                            </Grid>
                            {categoriesExist &&
                            <Grid item xs={12} lg={4}>
                                <SelectInput fullWidth label="Projektkategorie" style={{marginTop: 0}}
                                             source="projectCategory" choices={choicesCategories}/>
                            </Grid>
                            }
                            <Grid item xs={12} lg={6}>
                                <TextInput fullWidth label="Teilnehmername" source="contestantName"/>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextInput fullWidth label="Projekttitel" source="projectTitle"/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput fullWidth label="Straße" source="contestantStreet"/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextInput fullWidth label="PLZ" source="contestantZipcode"/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextInput fullWidth label="Ort" source="contestantCity"/>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <h5>Projektgalerie</h5>
                            </Grid>
                            <Grid item container spacing={2}>
                                <ProjectGallery/>
                            </Grid>
                            <Grid item xs={12}>
                                <h5>Projektdaten</h5>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextInput multiline fullWidth label="Antwort auf die 1. Frage" rows={16} source="contestantAnswer1"/>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextInput multiline fullWidth label="Antwort auf die 2. Frage" rows={16} source="contestantAnswer2"/>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextInput multiline fullWidth label="Antwort auf die 3. Frage" rows={16} source="contestantAnswer3"/>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab label="Kommentare">
                    <Grid container spacing={2} sx={{p: 2}}>
                        <ArrayInput label="Kommentare" source="comments">
                            <SimpleFormIterator inline>
                                <DateField disabled showTime label="Datum" source="createdAt"/>
                                <ReferenceField
                                    label="Author"
                                    source="author"
                                    reference="users">
                                    <FunctionField render={author => `${author.firstname} ${author.lastname}`}/>
                                </ReferenceField>
                                <TextField label="Kommentar" source="text"/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                </FormTab>
                <FormTab label="Projektgallerie">
                    <Grid container spacing={2} sx={{p: 2}}>
                        <ProjectGallery/>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export const ProjectCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <Grid container>
                <Grid item xs={12}>
                    <SelectInput fullWidth source="status" choices={status}/>
                </Grid>
                <Grid item md={6}>
                    <TextInput fullWidth label="Teilnehmername" source="contestantName"/>
                </Grid>
                <Grid item md={6}>
                    <TextInput fullWidth label="Projekttitel" source="projectTitle"/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput multiline label="Antwort auf die 1. Frage" source="contestantAnswer1"/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput multiline label="Antwort auf die 2. Frage" source="contestantAnswer2"/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);
